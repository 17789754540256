<template>
     <MobileHeader
      v-if="teamInfo.isFavTeam !== undefined"
      :isShowFavButton="true"
      :mobileHeaderTitle="mobileHeaderTitle"
      :isFav="isFavTeam"
      @toggleFavThis="toggleFavTeam($event)"
     ></MobileHeader>
        <div class="home has-header container">
            <div class="team-info__header-wrapper">
                <Icon :logoUrl=teamInfo.logo  class="team-info__header-logo"></Icon>
                <div class="team-info__header-title-container">
                    <div class="team-info__header-title-wrapper">
                        <div class="team-info__header-title mr-05rem">{{teamInfo.name}}</div>
                        <!-- <FavStarButton
                            class="btn-fav normal"
                            v-if="teamInfo.isFavTeam !== undefined && !isMobile"
                            :isFav="isFavTeam"
                            @toggleFavThis="toggleFavTeam($event)"
                        >
                        </FavStarButton> -->
                    </div>
                    <div class="team-info__header-country">{{teamInfo.country}}</div>
                </div>
            </div> 
              <div class="display-flex has-space mb-2rem">
                    <router-link class="sub-nav__child" :to="{path:`${o.path}`}"  v-for="(o, index) in tabs" :key="index" >
                        <span  class="sub-nav__child-text">{{$t(o.name)}}</span>
                        <div class="sub-nav__child-indicator"></div>
                    </router-link>
              </div>
                <div class="container">
                 <router-view ></router-view> 
                 </div>
              <div class="container" style="display:none">
            <div v-show="currentTabs === 1">

                    <div class="team-info__overview-card" v-for="(o, index) in overviewList" :key="index">
                        <div class="team-info__overview-header">{{o.title}}</div>
                        <div class="team-info__overview-body"  v-for="(level2, index) in o.child" :key="index">
                            <div class="">{{level2.key}}</div>
                            <div class="">{{level2.value}}</div>
                        </div>
                    </div>
                     
                    <div class="team-info__overview-card" v-for="(o, index) in overviewGoallList" :key="index">
                        <div class="team-info__overview-header">{{o.title}}</div>
                        <div class="team-info__overview-body__header">
                              <div class="team-info__overview-goal__player">
                                <div class="team-info__overview-goal__player-rank">#</div>
                                <div >Player</div>
                              
                            </div>
                            <div class="team-info__overview-goal__player-goal">Goal</div>
                        </div>

                        <div class="team-info__overview-body"  v-for="(level2, index) in o.child" :key="index">
                            <div class="team-info__overview-goal__player">
                                <div class="team-info__overview-goal__player-rank">{{level2.rank}}</div>
                                <!-- <div class="team-info__overview-goal__player-img"><img src="../../../../static/images/icons/icon_goal_player.png"></div> -->
                                <div class="team-info__overview-goal__player-name">{{level2.name}}</div>
                            </div>
                            <div class="team-info__overview-goal__player-goal">{{level2.goal}}</div>
                         
                        </div>
                    </div>
              
            </div>
            <div v-show="currentTabs === 2">
                <MatchContent :matchObj="matchObj" :isShowFav="true" class="container"></MatchContent>
            </div>
            <div v-show="currentTabs === 3">
                <div class="nav-pill__wrapper mb-main container" >
                    <div class="nav-pill selected" >Season 2020-2011</div>
                </div>
                    <div class="team-info__overview-card" v-for="(o, index) in overviewList" :key="index">
                        <div class="team-info__overview-header">{{o.title}}</div>
                        <div class="team-info__overview-body"  v-for="(level2, index) in o.child" :key="index">
                            <div class="">{{level2.key}}</div>
                            <div class="">{{level2.value}}</div>
                        </div>
                    </div>
                     
                    <div class="team-info__overview-card" v-for="(o, index) in overviewGoallList" :key="index">
                        <div class="team-info__overview-header">{{o.title}}</div>
                        <div class="team-info__overview-body__header">
                              <div class="team-info__overview-goal__player">
                                <div class="team-info__overview-goal__player-rank">#</div>
                                <div >Player</div>
                              
                            </div>
                            <div class="team-info__overview-goal__player-goal">Goal</div>
                        </div>

                        <router-link :to="{path:`/player/${level2.name}`}"  class="team-info__overview-body"  v-for="(level2, index) in o.child" :key="index">
                            <div class="team-info__overview-goal__player">
                                <div class="team-info__overview-goal__player-rank">{{level2.rank}}</div>
                                <!-- <div class="team-info__overview-goal__player-img"><img src="../../../../static/images/icons/icon_goal_player.png"></div> -->
                                <div class="team-info__overview-goal__player-name">{{level2.name}}</div>
                            </div>
                            <div class="team-info__overview-goal__player-goal">{{level2.goal}}</div>                        
                        </router-link>
                    </div>
            </div>
              <!-- <div  v-show="currentTabs === 4">       
                <MatchTable :matchTableList="matchTableList" :matchTableTipsList="matchTableTipsList"></MatchTable>
            </div> -->
            </div>
        </div>
	<PopUpSignInIndexMobile ref="PopUpSignInIndexMobileRef" :message="$i18n.t('FAV_LOGIN_TIPS')" ></PopUpSignInIndexMobile>
</template>

<script>
import MobileHeader from '@/components/mobile/Header.vue';
import MatchContent from '@/components/MatchContent.vue'
import MatchTable from '@/components/match/football/Table.vue'
import FavStarButton from '@/components/ui/FavStarButton.vue'
import PopUpSignInIndexMobile from "@/components/user/PopUpSignInIndexMobile.vue"

import {mapGetters,mapActions} from 'vuex'
export default {
 components: {
    MobileHeader,
    MatchContent,
    MatchTable,
    FavStarButton,
    PopUpSignInIndexMobile,
  },
  data() {
      return {
        mobileHeaderTitle:'TEAM',//TEAM_INFO
        teamKey:'',
        tabs:[
            {
                name:'PROFILE',
                path:'overview',
                value:1,
                selected:true
            },
            {
                name:'SCHEDULE',
                path:'match',
                value:2,
                selected:false
            },
            {
                name:'ROSTER',
                path:'squad',
                value:3,
                selected:false
            },
            //     {
            //     name:'ABOUT',
            //     path:'about',
            //     value:4,
            //     selected:false
            // }

        ],
        isFavTeam: false,
    }
  },
  created() {
      this.teamKey = this.$route.params.teamKey;
  },
    computed:{
        ...mapGetters([
            'teamInfo',
            'isMobile',
            'currentLocale',
            'isLogin',
            'currentPathName'
        ]),
    },
     watch:{
         currentLocale: {
             deep:true,
            handler(newVal,oldVal) {
             this.init();
               // this.getLeagueListForRankingTable(newVal.leagueIdList);
            }
        },
        isLogin: {
            deep: true,
            handler(newVal, oldVal) {
                this.init();
            }
        },
     },
  mounted(){
        this.init();
    },
  methods:{
        ...mapActions([
            "getBasketballTeamInfo",
            "addFavTeam",
            "unFavTeam",
            "commonVerifyLogin",
            "favLoginTipsChangeEvent",
            "toastrFavChangeEvent",
            "popUpSignInModuleEvent",
        ]),
        init() {
            this.commonVerifyLogin();
            this.getTeamInfoData();
        },
        async getTeamInfoData(){
          let params = {
            teamId : this.teamKey,
          }
          const result = await this.getBasketballTeamInfo(params);
          this.isFavTeam = this.teamInfo.isFavTeam;
        },
        async toggleFavTeam(data) {
            if (this.isLogin) {
                let params = {
                    teamId: this.teamKey,
                };
                let paramsToastr = {
                    isOpen: true,
                    msg: this.teamInfo.name,//this.$i18n.t("TEAM")
                    isAddedFav: false,
                };
                if (this.isFavTeam) {
                    const result = await this.unFavTeam(params);
                    if (result.message === "Success") {
                        this.isFavTeam = false;

                        paramsToastr.isAddedFav = false;
                        this.toastrFavChangeEvent(paramsToastr);
                    }
                } else {
                    const result = await this.addFavTeam(params);
                    if (result.message === "Success") {
                        this.isFavTeam = true;

                        paramsToastr.isAddedFav = true;
                        this.toastrFavChangeEvent(paramsToastr);
                    }
                }               
            } else if (!this.Login && !this.isMobile) {
                this.favLoginTipsChangeEvent({isOpen: true, event: data, callBackUrl: this.currentPathName,}) 
            } else if (!this.isLogin && this.isMobile) {
                this.$refs.PopUpSignInIndexMobileRef.handleOpen();
            }
          
        },
        
        handleAuthentication(type) {
            this.popUpSignInModuleEvent(type);
            this.$refs.PopUpSignInIndexMobileRef.handleClose(); 
        },
  }
}
</script>

<style>
    .team-info__header-wrapper{
        display: flex;
        justify-content: start;
        align-items: center;
        /* flex-direction: column; */
        margin-bottom: 2rem;
    }
    .team-info__header-logo{
        /* margin-bottom: 1rem; */
        width: 4.167rem;
        height: 4.167rem;
        margin-right: 1.417rem;
    }
    .team-info__header-title-wrapper{
        display: flex;
        align-items: center;
        margin-bottom: 4px;
    }
    .team-info__header-title{
        font-size: 1.375rem;
        font-weight: 700;
    }
    .team-info__header-country{
        font-size: 0.875rem;
        font-weight: 400;
        color: var(--color-grey-80);
    }
    .team-info__overview-card{
        margin-bottom: 2rem;
    }
     .team-info__overview-header{
        margin: 0 1rem;
         margin-bottom: .5rem;
         font-size: .87rem;
         font-weight: 400;
     }
    .team-info__overview-body{
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size:.7rem;
        padding: .5rem 0;
        border-bottom:.1rem solid #212B30;
        color:var(--color-grey-light);
        margin: 0 1rem;
    }
    .team-info__overview-body:hover .team-info__overview-goal__player-name{
        color: var(--color-hover)
    }
    .team-info__overview-body__header{
        display: flex;
        justify-content: space-between;
        background-color: var(--color-grey-20);
        padding: 0.5rem 1rem;
    }
    .team-info__overview-body:last-child{
         border-bottom:none
    }
    .team-info__overview-body__left{
        flex:1;
        text-align: left;
    }
    .team-info__overview-body__right{
        flex:2;
        text-align: right;
    }
    .team-info__overview-goal__player{
        display:flex;
        align-items: center;
        font-size: .85rem;
        flex:2
    }
    .team-info__overview-goal__player-rank{
        margin-right: .8rem;
        width: 1rem;
        text-align: center;
    }
    .team-info__overview-goal__player-img{
        border-radius: 50%;
        overflow: hidden;
        margin-right: .5rem;
    }
    .team-info__overview-goal__player-goal{
        font-size: .85rem;
    }
    @media (max-width: 768px){
        .team-info__header-wrapper{
            margin: 0.5rem 1rem 1.5rem;
        }
        .team-info__header-logo{
            width: 1.25rem;
            height: 1.25rem;
            margin-right: 0.625rem;
        }
        .team-info__header-title-wrapper{
            margin-bottom: 0;
        }
        .team-info__header-title{
            font-size: 0.875rem;
        }
        .team-info__header-country{
            font-size: 0.688rem;
        }
    }
</style>